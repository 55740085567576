import * as Cookies from 'js-cookie';

interface CookieOptions {
  expires?: number
}

class CookieService {
  public setSessionCookie = (cookieName: string, cookieValue: string) =>
      this.setCookie(
          cookieName,
          cookieValue,
      );

  public setExpirableCookie = (cookieName: string, cookieValue: string, days?: number) =>
      this.setCookie(
          cookieName,
          cookieValue,
          {
            expires: days,
          }
      );

  private setCookie = (cookieName: string, cookieValue: string, cookieAttributes?: CookieOptions) =>
      Cookies.set(
          cookieName,
          cookieValue,
          cookieAttributes
      );

  public getCookie = (cookieName: string) => Cookies.get(cookieName);

  static exists = (cookieName: string) => Cookies.get(cookieName) !== undefined;
}

export default CookieService;
