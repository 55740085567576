import {Brand} from "../../types/Brand";
import GoogleTagManager from "./GoogleTagManager";
import GoogleTracking from "./GoogleTracking";
import GA4Tracking from "./GA4Tracking";
import {Country} from "../../types/Country";
import Whitelabel from "../Whitelabel";

export default class GoogleTrackingFactory {
    static createFor(brand: Brand, country: Country): GoogleTracking {
        if (Whitelabel.isWhitelabelSite(brand, country)) {
            return new  GoogleTagManager();
        }
        return new GA4Tracking()
    }
}