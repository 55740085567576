import GoogleTracking from "./GoogleTracking";
import FeatureFlags, {GOOGLE_ONE_TAP_DEBUG_FEATURE_FLAG} from "../../FeatureFlags";

export default class GoogleTagManager implements GoogleTracking {
    trackEvent(name: string, otherArguments = {}) {
        this.gtag({
            'event': name,
            ...otherArguments
        });
    }

    trackTest(testId: number, testName: string, testVariant: string) {
        this.gtag({
            'event': 'split_test',
            'split_test': testName,
            'split_test_option': testVariant,
            'split_test_id': testId
        })
    }

    private gtag(argument: Object){
        let featureFlags = new FeatureFlags();
        if (featureFlags.isActiveFeature(GOOGLE_ONE_TAP_DEBUG_FEATURE_FLAG)) {
            console.log(`Send event: ${JSON.stringify(argument)}`);
        }
        // @ts-ignore
        dataLayer.push(argument)
        if (featureFlags.isActiveFeature(GOOGLE_ONE_TAP_DEBUG_FEATURE_FLAG)) {
            console.log(`Event sent: ${JSON.stringify(argument)}`);
        }
    }
}