import {Country} from "../types/Country";
import {Brand} from "../types/Brand";

export default class Whitelabel {
    private static migratedBrands = [
        Brand.PROPERATI, Brand.LAMUDI
    ]

    private static mitulaMigratedCountries = [
        Country.VE
    ]

    static isWhitelabelSite(brand: Brand, country: Country): boolean {
        return this.migratedBrands.includes(brand) ||
            brand == Brand.MITULA && this.mitulaMigratedCountries.includes(country)
    }
}